<script>
    import Layout from "@/router/layouts/main";
    import appConfig from "@/app.config";
    import PageHeader from "@/components/page-header";
    
    // import Swal from "sweetalert2";
    // import axios from "axios";
    /**
     * Dashboard Component
     */
    export default {
        page: {
            title: "Tambah Posisi",
            meta: [
                {
                    name: "description",
                    content: appConfig.description,
                },
            ],
        },
        components: {
            Layout,
            PageHeader,
        },
        data() {
            return {
                title: "Tambah Posisi",
                items: [
                    {
                        text: "Master",
                        href: "/",
                    },
                    {
                        text: "Posisi",
                        href: "/master/Posisi",
                    },
                    {
                        text: "Tambah Posisi",
                        active: true,
                    },
                ],
                // Catch Error Axios
                axiosCatchError: null,
                // variable Page
                master_role: ['one', 'two', 'three'],
                role_selected: ''
            };
        },
        mounted() {},
        methods:{
        },
    };
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-lg-8">
                <div class="card">
                    <b-form class="p-2">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-12">
                                    <b-form-group class="mb-3" label="Posisi" label-for="formrow-Posisi-input">
                                        <b-form-input id="formrow-Posisi-input" type="text"></b-form-input>
                                    </b-form-group>
                                    <b-form-group class="mb-3" label="Jumlah Jabatan" label-for="formrow-Posisi-input">
                                        <b-form-input id="formrow-Posisi-input" type="text"></b-form-input>
                                    </b-form-group>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer">
                            <b-button type="submit" variant="primary" class="m-1"><i class="fa fa-save"></i> Simpan</b-button>
                            <b-button type="reset" variant="danger" class="m-1"><i class="fa fa-refresh"></i> Reset</b-button>
                        </div>
                    </b-form>
                </div>
            </div>
        </div>
    </Layout>
</template>
